import React, { useState } from "react";

function YouTubePlaylist() {
  const [mainVideo, setMainVideo] = useState(
    "https://www.youtube.com/embed/videoseries?list=PL__SqZ6RVE6TSKvfj5Iq1P4e3exkOoPwz"
  );

  const videos = [
    { id: "zpUa-BIbazw", title: "Happy Holidays" },
  ];

  const handleVideoSelect = (videoId) => {
    setMainVideo(`https://www.youtube.com/embed/${videoId}`);
  };

  return (
    <div className="youtube-playlist-container">
      {/* Main video player */}
      <div className="main-video">
        <iframe
          className="video-frame"
          src={mainVideo}
          title="YouTube Playlist Player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <br />
    </div>
  );
}

export default YouTubePlaylist;
