import React, { useState } from "react";

function YouTubePlaylist() {
  const [mainVideo, setMainVideo] = useState(
    "https://www.youtube.com/embed/videoseries?list=PL__SqZ6RVE6SHqi9l3j66n4kzOWRWB697"
  );

  const videos = [
    { id: "Opvr4durIos", title: "Book Us Today!" },
    { id: "GMKaHe1EkL8", title: "Little Texas Belleville" },
    { id: "jvX68V7iozU", title: "Blu Martini " },
    { id: "CwYqGJi06Qo", title: "Music In The Park " },
    { id: "6G9TqqLDUhs", title: "Confederation Park" },
    { id: "0QnbiMJFgok", title: "Royal Taven 2.0 " },
  ];

  const handleVideoSelect = (videoId) => {
    setMainVideo(`https://www.youtube.com/embed/${videoId}`);
  };

  return (
    <div className="youtube-playlist-container">
      {/* Main video player */}
      <div className="main-video">
        <iframe
          src={mainVideo}
          title="YouTube Playlist Player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
<br/>
      {/* Video thumbnails */}
      <div className="video-thumbnails">
        {videos.map((video, index) => (
          <div
            key={index}
            className="thumbnail"
            onClick={() => handleVideoSelect(video.id)}
          >
            <img
              src={`https://img.youtube.com/vi/${video.id}/0.jpg`}
              alt={video.title}
            />
            <p>{video.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default YouTubePlaylist;
