import React,{useState} from 'react'
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from './contactbutton2'
import '../component/common/swiper.css'
import kiri from '../assets/images/1.png';
import G from '../assets/images/2.png';
import Greg from '../assets/images/3.png';
import Dave1 from '../assets/images/5.png';
import Dave2 from '../assets/images/4.png';
import Davebio from './Bios/Dave'
import Gbio from './Bios/G'
import Gregbio from './Bios/Greg'
import Kiribio from './Bios/Kiri'
import Reconbio from './Bios/Recon'
import VSlider from './videoslider'
import Team from './Bios/Biomobile'


const imageArray2 = [
  'https://i.imgur.com/sc2bArz.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
];

const PortfolioLanding = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="active-dark">
      <Helmet pageTitle="Book River Cats Today & Bring High-Energy Music to Your Venue" />

      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      
      
      {/* Start Portfolio Area */}
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
          <br/>
                 <section class="light">
	<div class="container py-2">
  <VSlider/>
		
  <article class="postcard light red">
			<div class="postcard__text t-dark">
				<h1 class="postcard__title red">River Cats: Bringing High-Energy Music to Your Venue</h1>
				<div class="postcard__subtitle small">
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt" id="aboutus"> <p>River Cats: Bringing High-Energy Music to Your Venue</p>

<p>Looking for a band that delivers an unforgettable live music experience? <strong>River Cats</strong> combines the best of funk, rock, and country into a dynamic performance <br/>that captivates audiences and keeps them coming back for more.</p>

<p>Our experienced, tight-knit ensemble knows how to energize any crowd, making your venue <em>the</em> destination for music lovers. Whether it's a lively bar, a festival, or a private event space, we bring the energy, talent, and stage presence that will set your event apart.</p>

<p><strong>Why Book River Cats for Your Venue?</strong></p>
<ul>
    <li>A proven crowd-pleaser with a diverse and engaging setlist.</li>
    <li>Professional and reliable, ensuring smooth performances every time.</li>
    <li>Tailored performances to match your venue’s vibe and audience.</li>
</ul>

<p>Make your venue the talk of the town. <strong>Book River Cats today</strong> and give your audience a live music experience they won’t forget. Contact us now to reserve your date!</p>

   
   <ContactButton/>
        </div>
			</div>
    </article>
    <br/>
    <article class="postcard light red">
    <Team/>
    <span class="cards-cover">
          <article class="grid-x5--cards tc-dark-orange">
          <div class="cards ">
              <img src={Dave1} alt="Dave Guitarist"></img>
              <p>Dave</p>
              <Reconbio/>
              <p class="bg-dark-orange">Guitar</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-light-purple">
          <div class="cards ">
              <img src={G} alt="G Bass/Synth"></img>
              <p>G</p>
              <Gbio/>
              <br/>
             <p class="bg-light-purple">Bass</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-orange">
          <div class="cards ">
              <img src={kiri} alt="Kiri Vocals"></img>
              <p>Kiri</p>
              <Kiribio/>
              <br/>
            <p class="bg-orange">Vocals</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-light-purple">
          <div class="cards ">
              <img src={Greg} alt="Greg Drums/Percussion"></img>
              <p>Greg</p>
              <Gregbio/>
              <br/>
             <p class="bg-light-purple">Drums</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-dark-orange">
          <div class="cards tc-dark-orange">
              <img src={Dave2} alt="Dave Guitarist"></img>
              <p>Dave</p>
              <Davebio/>
              <br/>
            <p class="bg-dark-orange">Guitar</p>
          </div>
      </article>
      
      </span>
		</article>
		

	</div>
</section>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}
      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;